$tstar-font: roboto-condensed-regular, Helvetica, Arial, Sans-Serif;
$tstar-bold-font: roboto-condensed-bold, Helvetica, Arial, Sans-Serif;
$times-font: TimesNewRoman, "Times New Roman", Times, serif;
$roboto-condensed-bold-font: roboto-condensed-bold, Helvetica, Arial, Sans-Serif;
$roboto-condensed-regular-font: roboto-condensed-regular, Helvetica, Arial,
  Sans-Serif;

$ano-bold_regular-font: noto-black, Helvetica, Arial, sans-serif;
$ano_regular-font: noto_regular, Helvetica, Arial, sans-serif;
$product-display-name: $ano-bold_regular-font;
$product-shade-name: $ano-bold_regular-font;

$headline-ano-black-font: $ano-bold_regular-font;

$opc_update: true;
$enable_cookie-img: true;
$password_strengthen: true;

// 2020 styling refresh.
$cr20: true;

$gnav_update: true;

// 2021 styling refresh.
$cr21: true;
$hide-rating-count--spp: true;
$fonts_update: true;

// Appointment Booking
$appt_booking: true;
$appt_booking_static_header: true;
$appt-booking-tooltip-text: $ano_regular-font;
$appt_button_redesign: true;
$hide-notify-me-btn: true;
